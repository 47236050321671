import React, { useState } from "react"
import Block from "./Block"
import Heading from "./Heading"
import Expandable from "./Expandable"
import {
  QuestionIcon,
  StopwatchIcon,
  InfoIcon,
  ArchiveIcon,
  ChecklistIcon,
  PeopleIcon,
  ChevronRightIcon,
} from "@primer/octicons-react"
import { IconContext } from "react-icons"
import { BsArrowReturnRight } from "@react-icons/all-files/bs/BsArrowReturnRight"
import { HiOutlineArrowNarrowRight } from "@react-icons/all-files/hi/HiOutlineArrowNarrowRight"
import { MdPauseCircleOutline } from "@react-icons/all-files/md/MdPauseCircleOutline"
import { MdHistory } from "@react-icons/all-files/md/MdHistory"
import { CgChevronDoubleRightO } from "@react-icons/all-files/cg/CgChevronDoubleRightO"

import { BsPlus } from "@react-icons/all-files/bs/BsPlus"
import { MdExpandMore } from "@react-icons/all-files/md/MdExpandMore"
import { MdExpandLess } from "@react-icons/all-files/md/MdExpandLess"
import { MdChevronRight } from "@react-icons/all-files/md/MdChevronRight"

const findCriteria = (id, allCriteria) => {
  const { node } = allCriteria.find(
    ({ node: criterion }) => criterion.id === id
  )
  console.log("found", { node })
  return node
}

const AcceptanceCriterion = ({
  id,
  allCriteria,
  parents = [],
  givens = [],
}) => {
  const {
    Title: title,
    dependants,
    Given: given,
    When: when,
    Then: then,
  } = findCriteria(id, allCriteria)
  const [expanded, setExpanded] = useState(true)
  console.log({ parents })
  const buildGiven = inputPhrases => {
    const phrases = inputPhrases.filter(x => x !== "" && x !== null)
    let result = []
    if (phrases instanceof Array) {
      for (let phrase of phrases) {
        result.push(
          <div
            className={`flex justify-around gap-1 items-center place-content-center flex-row`}
          >
              <div>
                <BsPlus size={16} />
              </div>
            <div>{phrase}</div>
          </div>
        )
      }
    } else {
      //result.push(phrases)
    }

    return result ? <div className="flex flex-col gap-2 items-start">{result}</div> : ""
    // if (phrases?.length > 2) {
    // } else {
    //   return <div className="gap-2 items-start">{result}</div>
    // }
  }

  const calculatedGiven = buildGiven([...givens, given])
  console.log({calculatedGiven})


  const fullLayout = (
    <div
      className={`flex flex-col`}
    >
      <div className="border border-bhd-blue rounded p-3 pt-0 flex flex-col bg-white">
        <div>
          <div className="text-xl my-2 text-bhd-blue font-medium">{title}</div>
        </div>
        {calculatedGiven.toString() !== "" && (
        <div className="flex flex-row gap-2 items-center">
          <div>
            <MdHistory size={24} />
          </div>
          <div>{calculatedGiven.toString()}</div>
        </div>
        )}
        <div className="flex flex-row gap-2 items-center">
          <div>
            <CgChevronDoubleRightO size={24} />
          </div>
          <div>{when}</div>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <div>
            <MdPauseCircleOutline size={26} className="rotate-90" />
            {/* <BsArrowReturnRight size={24} /> */}
          </div>
          <div>{then}</div>
        </div>
        {dependants.length > 0 && (
          <div className="flex flex-col gap-2 mt-2">
            {dependants.map(({ id }) => (
              <AcceptanceCriterion
                id={id}
                allCriteria={allCriteria}
                parents={[...parents, id]}
                givens={[...givens, given, when]}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )

    return (
      <div
        className={`flex flex-${expanded ? "col" : "row"} gap-2 items-${
          expanded ? "start" : "center"
        }`}
      >
          <a
            onClick={() => setExpanded(!expanded)}
            className="cursor-pointer"
          >
            {expanded ? (
              <MdExpandMore size={24} />
            ) : (
              <MdChevronRight size={24} />
            )}
          </a>
        <div className="w-full">{expanded ? fullLayout : (<div className="border border-bhd-blue rounded text-lg p-2 text-bhd-blue font-medium">{title}</div>)}</div>
      </div>
    )
}
export default AcceptanceCriterion
